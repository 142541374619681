.App {
    padding: 3rem;
    text-align: center;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.typingArea {
    width: "100%";
    outline-width: 0;
    outline: none !important;
}

.hg-rows {
    padding-left: 16px;
    padding-right: 16px;
}

.hg-button {
    height: 0px !important;
    padding: 18px 0px 18px 0px !important;
    font-weight: bold;
    border-bottom: 1px solid #9595959A !important;
}

.hg-theme-darkmode {
    background-color: #60606070 !important;
}

.hg-theme-darkmode .hg-button:not(.hg-correct):not(.hg-exists):not(.hg-missing) {
    background: #606060 !important;
    color: #EEEEEE;
}

.hg-correct {
    background: #006A00 !important;
    color: #EEEEEE;
}

.hg-exists {
    background: #AF5A00 !important;
    color: #EEEEEE;
}

.hg-missing {
    background: #101010 !important;
    color: #EEEEEE;
}

.containerSegment{
    /* background-color: #f5f8fa !important; */
    background-color: #edf4f8 !important;
    max-width: 1200px;
}

.bottomSegment {
    background-color: #132044 !important;
    max-width: 1200px;
}

.lightSegment{
    background-color: #FFFFFF !important;
    padding: 0px !important;
}

.darkSegment{
    background-color: #000000 !important;
    padding: 0px !important;
}

.MuiSwitch-track {
    background-color: #777777 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

.inputLine {
    padding-top: 24px;
}

.inputLine, .outputLine {
    display: flex;
    justify-content: center;
}

.leftAlign {
    justify-content: left !important;
}

.slashSeparator {
    font-size: 42px;
    font-weight: bold;
    align-self: center;
}

.rangeToLabel {
    font-size: 24px;
    font-weight: normal;
    align-self: center;
}

.inputField {
    background-color: #FFFFFF;
}

.inputField input {
    font-size: 18px !important;
}

.radioToggleButton {
    line-height: 0.4em !important;
}

.selectedRadioButton {
    background-color: #ABA7E3 !important;
    -webkit-box-shadow: inset 1px 1px 2px 1px #333 !important;
    -moz-box-shadow:    inset 1px 1px 2px 1px #333 !important;
    box-shadow:         inset 1px 1px 2px 1px #333 !important;
}

.formButton {
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.5), inset 0 -1px 2px rgba(170, 170, 170, 0.8), 0 3px 5px -3px rgba(100, 100, 100, 0.8) !important;
}

.dropdown.icon {
    padding-top: 0px !important;
    padding-right: 0px !important;
    top: 0.5em !important;
    right: 0.1em !important;
    font-size: 18px !important;
}

.continueButton, .previousPageButton, .saveProgressButton {
    line-height: 0.3em !important;
    height: 2.5em !important;
}

.defaultSetButton {
    background-color: #ABA7E3 !important;
}

.middleAlign {
    vertical-align: bottom !important;
}

h1 {
    margin-bottom: 0px;
}

h2 {
    margin-bottom: 0px;
}

h3 {
    margin-bottom: 0px;
}

.App {
    position: relative;
    min-height: 100vh;
}

.App, body {
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.bottomNavigationBar {
    position: absolute;
    display: flex;
    text-align: right;
    bottom: 38px;
    width: 100%;
}

.bottomNavigationButtons {
    width: 100%;
}

footer, .footerClass {
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(15, 56, 110, 0.95));
    position: absolute;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    left: 0;
    bottom: 0;
    padding: 8px;
    padding-top: 30px;
    width: 100%;
    overflow-x: hidden;
}